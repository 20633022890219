import React, { useEffect } from "react";
import { ExtendedFormState } from "../useExtendedForm";
import { CnbButton, CnbData, CnbDomain, CnbOptions } from "./types";
import { useTheme, useMediaQuery } from "@mui/material";
import { DomainContext } from "../../domain";
import cloneDeep from "lodash/cloneDeep";
import { Action, Button } from "../../lib/cnb/api/public";

interface ButtonPreviewProps {
  formal: ExtendedFormState<Button>;
}

function resetButton() {
  // @ts-ignore
  window.CNB_DATA = null;

  // Delete all existing Buttons
  const existing = document.getElementsByClassName("call-now-button");
  for (let i = 0; i < existing.length; i++) {
    const item = existing.item(i);
    if (item) {
      const parent = item.parentNode;
      if (parent) {
        parent.removeChild(item);
      }
    }
  }
}

function renderButton(data: CnbData) {
  if ("CNB" in window) {
    // @ts-ignore
    // noinspection JSUnresolvedReference
    window.CNB_DATA = data;
    // @ts-ignore
    window.CNB.render();
  }
}

function toCnButton(
  button: Button,
  actions: Action[],
  domain: CnbDomain,
  id: string,
): CnbButton {
  return {
    id: id,
    name: button.name,
    active: true,
    type: button.type,
    domain: domain,
    // @ts-ignore
    actions: actions.map((action) => action.id),
    conditions: [],
    domainId: "this-domain",
    // @ts-ignore
    multiButtonOptions: {
      id: "this-mbo",
      ...button.multiButtonOptions,
    },
    // @ts-ignore
    options: {
      ...button.options,
      // We overwrite the position to always be bottom right (for the preview)
      displayMode: "ALWAYS",
      position: "FIXED",
      placement: "BOTTOM_RIGHT",
    },
  };
}
const ButtonPreview = ({ formal }: ButtonPreviewProps) => {
  const { currentDomain } = React.useContext(DomainContext);
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  const userId = "";

  const options = {
    cssLocation: process.env.REACT_APP_STATIC_URL + "/css/main.css",
    jsLocation: process.env.REACT_APP_STATIC_URL + "/js/client.js",
    apiRoot: process.env.REACT_APP_API_URL,
    staticRoot: process.env.REACT_APP_STATIC_URL,
    userRoot: process.env.REACT_APP_USER_URL,
    date: new Date().getTime(),
    ip: "127.0.0.1",
    storageType: "GCS",
  } as CnbOptions;

  const domain = {
    id: "this-domain",
    name: window.location.hostname,
    type: currentDomain?.type,
    aliases: [],
    expires: 0,
    renew: false,
    timezone: currentDomain?.timezone,
    trackGA: false,
    trackConversion: false,
    user: "this-user",
    properties: undefined,
  } as CnbDomain;

  const id = "cnb-preview-react";

  const actions = cloneDeep(formal.values.actions);
  // In order for the preview to work, assign some random ID to the action
  actions.forEach((action, index) => {
    action.id = index.toString();
  });
  // @ts-ignore
  const button = toCnButton(formal.values, actions, domain, id);
  // Let's create a CnbData
  // @ts-ignore (ignore that actions is of a slightly different type, not important for the preview)
  const data = {
    userId: userId,
    options: options,
    conditions: [],
    actions: actions,
    domains: [domain],
    buttons: [button],
  } as CnbData;

  // Delete all existing Buttons
  resetButton();
  if (greaterThanSm) {
    renderButton(data);
  }

  useEffect(() => {
    return () => {
      resetButton();
    };
  }, []);

  return <></>;
};

export default ButtonPreview;
